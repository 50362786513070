import { template as template_fc770ee60e31413a9073d2cb4b639492 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fc770ee60e31413a9073d2cb4b639492(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
