import { template as template_f739786d933c4a10bd549f34f48a40eb } from "@ember/template-compiler";
const FKLabel = template_f739786d933c4a10bd549f34f48a40eb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
