import { template as template_e6d5a628a5b6461a84c702108c22c939 } from "@ember/template-compiler";
const WelcomeHeader = template_e6d5a628a5b6461a84c702108c22c939(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
