import { template as template_c313e08d8d4940c9aba1ab6a7f0b33a9 } from "@ember/template-compiler";
const FKText = template_c313e08d8d4940c9aba1ab6a7f0b33a9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
